import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';
import utilsService from '../services/utils.service';
import userStore from './user.store';
import constants from '../constants/constants';
import {profileApi} from '../entities/profiles';
import notificationsStore from './notifications.store';

class ProfileStore {
    @observable profileToShow = null;
    @observable profiles = {};
    @observable profilesCount = {};
    @observable contactInfo = {};

    @observable profilesToShow = []; //pure response

    //filtered profiles response for activities companies
    @observable profilesCompaniesActivities = {};

    // job matches
    @observable profilesJobsMatches = {}; // main response
    @observable detailedProfilesForJobsMatches = {}// for more details

    @observable isLoadingSummaries = false;


    @action.bound
    async getProfiles() {
        const response = await fetchService.authenticatedGet(
            `/profiles`);

        const normalizedData = utilsService.normalizeApiData(response.data);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
        this.profilesToShow = response.data;
        return response.data;
    }

    @action.bound
    async getProfileById(profileId, shouldForceCall) {
        const profileSlug = this.findProfileSlugById(profileId);
        if (this.profiles[profileSlug] && !shouldForceCall) {
            this.profileToShow = this.profiles[profileSlug];
        } else {
            const response = await fetchService.authenticatedGet(`/profiles/${profileId}`);
            this.profiles[response.data.slug] = response.data;
            this.profileToShow = response.data;
        }

        return this.profileToShow;
    }

    async getProfileByCompanyId(companyId) {
        const response = await fetchService.authenticatedPost(`/profiles-by-company/`, {companyId: companyId});
        return response.data;
    }

    @action.bound
    async getProfilesByIds(ids, includeDetails = true) {
        const uniqIds = utilsService.uniqArrayValues(ids);

        const filteredIds = [];
        const values = Object.values(this.profiles);
        uniqIds.forEach(id => {
            const prof = values.find(po => po._id === id);
            if (!prof) {
                filteredIds.push(id);
            }
        });

        if (filteredIds.length > 0) {

            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);

            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(
                    `/profiles?ids=${chunk.join(',')}&includeProfile=${includeDetails}`);
            });

            const response = await Promise.all(promises);

            let data = [];
            response.forEach(resp => {
                const respData = Array.isArray(resp.data) ? resp.data : [];
                data = [...data, ...respData];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
            return data;
        }
    }

    @action.bound
    async searchProfiles(ids = [], slugs = []) {
      const uniqueIds = utilsService.uniqArrayValues(ids);
      const uniqueSlugs = utilsService.uniqArrayValues(slugs);

      const profilesKeys = Object.keys(this.profiles);
      const profileValues = Object.values(this.profiles);
      const profileIds = profileValues.map((p) => p._id);

      const filteredIds = utilsService.filterStoreIds(uniqueIds, profileIds);
      const filteredSlugs = utilsService.filterStoreIds(uniqueSlugs, profilesKeys);

      if (filteredIds.length > 0 || filteredSlugs.length > 0) {
        const response = await fetchService.authenticatedPost(`/profiles-search`, {
          ids: filteredIds.join(','),
          slugs: filteredSlugs.join(','),
        });

        const data = response && Array.isArray(response.data) ? response.data : [];
        const normalizedData = utilsService.normalizeApiData(data);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
      }
    }

    @action.bound
    async getProfilesBySlugs(slugs, forceGetData, filter) {
        const uniqIds = utilsService.uniqArrayValues(slugs);
        let profilesKeys;
        if(filter === constants.PROFILES_FOR_COMPANIES_ACTIVITIES_SCREEN) {
            profilesKeys = Object.keys(this.profilesCompaniesActivities);
        }
        else if(filter === constants.PROFILES_FOR_JOBS_MATCHES_SCREEN) {
            profilesKeys = Object.keys(this.profilesJobsMatches);
        }
        else {
            profilesKeys = Object.keys(this.profiles)
        }
        let filteredIds = utilsService.filterStoreIds(uniqIds, profilesKeys);

        if (forceGetData) {
            filteredIds = uniqIds;
        }

        if (filteredIds.length > 0) {

            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);

            const promises = chunks.map(chunk => {
                let url = `/profiles?slugs=${chunk.join(',')}`;
                if(filter) {
                    url +=  `&filter=${filter}`
                }
                return fetchService.authenticatedGet(url);
            });

            const response = await Promise.all(promises);
            let data = [];
            response.forEach(resp => {
                const respData = Array.isArray(resp.data) ? resp.data : [];
                data = [...data, ...respData];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            if(filter === constants.PROFILES_FOR_COMPANIES_ACTIVITIES_SCREEN) {
                this.profilesCompaniesActivities = utilsService.mergeApiData(normalizedData, this.profilesCompaniesActivities);
            }
            else if(filter === constants.PROFILES_FOR_JOBS_MATCHES_SCREEN) {
                this.profilesJobsMatches = utilsService.mergeApiData(normalizedData, this.profilesJobsMatches);
            }
            else {
                this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
            }

        }
    }

    @action.bound
    async getDetailedProfileForJobMatch(profileSlug) {
        let profilesKeys = Object.keys(this.detailedProfilesForJobsMatches)
        const filteredIds = utilsService.filterStoreIds([profileSlug], profilesKeys);

        if (filteredIds.length > 0) {

            const response = await fetchService.authenticatedGet(
                `/profiles?slugs=${profileSlug}&filter=${constants.DETAILED_PROFILES_FOR_JOBS_MATCHES_SCREEN}`);
            if(response?.data && Array.isArray(response?.data) && response?.data.length) {
                const normalizedData = utilsService.normalizeApiData([response?.data[0]]);
                this.detailedProfilesForJobsMatches = utilsService.mergeApiData(normalizedData, this.detailedProfilesForJobsMatches);
            }
        }
    }


    @action.bound
    async getProfilesByRecruiterId(recruiterId) {
        const response = await fetchService.authenticatedGet(
            `/profiles?recruiterId=${recruiterId}`);
        const normalizedData = utilsService.normalizeApiData(response.data);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
        this.profilesToShow = response.data;
        return response.data;
    }

    @action.bound
    async getCv(profileSlug, processSlug) {
        const response = await fetchService.authenticatedGet(`/profiles/${profileSlug}/cv?processSlug=${processSlug}`);
        return response.data && response.data.length > 0 ? response.data[0] : false;
    }

    @action.bound
    async getCvForEdit(profileSlug, cvId) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/cv-for-edit`, {cvId: cvId});
        return response && response.data;
    }

    @action.bound
    async getCvTypesStatus(profileSlug) {
        const response = await fetchService.authenticatedGet(`/profiles/${profileSlug}/cv-status`);
        const profile = response.data.profile;
        if(profile) {
            const profileSlug = profile.slug;
            this.profiles[profileSlug] = profile;
        }
        return response.data;
    }

    @action.bound
    async uploadCv(profileSlug, cvType, fileData) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/upload-cv`, {cvType: cvType, file: fileData});
        this.profiles[response.data.slug] = response.data;
        return response.data;
    }

    @action.bound
    async deleteCv(profileSlug, cv) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/delete-cv`, cv);
        if(response) {
            this.profiles[response.data.slug] = response.data;
            return response.data;
        }
        else {
            return null;
        }
    }

    @action.bound
    async updateCV(profileSlug, cv) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/update-cv-params`, cv);
        this.profiles[response.data.slug] = response.data;
        return response.data;
    }


    @action.bound
    async saveCv(profileSlug, fileName, html, cvType, lang, isPrimary, cvId) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/cv-save`, {fileName: fileName, html: html, cvType: cvType, lang: lang, isPrimary: isPrimary, cvId: cvId});
        const res = response.data ? response.data : undefined;
        return res;
    }

    @action.bound
    async downloadCv(profileSlug, body) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/cv-download`, body);
        const res = response.data ? response.data : undefined;
        return res;
    }

    @action.bound
    async createCoveredCV(profileSlug, cvId) {
        const response = await fetchService.authenticatedPost(`/profiles/${profileSlug}/create-covered-cv`, {cvId: cvId});
        const cvUrl = response.data ? response.data : undefined;
        const profile = response.data && response.data.profile;
        if(profile) {
            profileStore.profiles[profile.slug] = profile;
        }
        return cvUrl;
    }

    @action.bound
    async removeProfileImage(slug) {
        const response = await fetchService.authenticatedDelete(`/profiles/${slug}/delete-profile-image`);
        if(response && response.data && response.data.status === 200) {
            const normalizedData = utilsService.normalizeApiData([response.data.data]);
            this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
        }
        return response.data;
    }

    @action.bound
    addProfileToList(profile) {
        const normalizedData = utilsService.normalizeApiData([profile]);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
    }

    findProfileSlugById(id) {
        return Object.keys(this.profiles).find(key => this.profiles[key]._id === id);
    }

    findProfileSlugByIds(ids) {
        const tempArr = [];
        const keys = Object.keys(this.profiles);
        const length = keys.length;
        for (let i = length - 1; i >= 0; i--) {
            const slug = keys[i];
            const profile = this.profiles[slug];
            if (ids.includes(profile._id)) {
                tempArr.push(profile);
            }
        }

        return tempArr;
    }

    @action.bound
    async countProfilesByData(data) {
        const response = await fetchService.authenticatedPost(
            `/profiles-count`, data);
        this.profilesCount = response.data;
        return response.data
    }

    filterNonExistInStoreProfiles(slugs) {
        const temp = [];
        slugs.forEach(slug => {
            if (!this.profiles[slug]) {
                temp.push(slug);
            }
        });

        return temp;
    }

    @action.bound
    async getContactInfo(profile, process, job) {
        let res = false;
        const user = userStore.user;
        const userCompany = userStore.userCompany;
        const body = {
            profileId: profile._id,
            profileSlug: profile.slug,
            processId: utilsService.getObjValue(process, '_id'),
            processSlug: utilsService.getObjValue(process, 'slug'),

            userId: utilsService.getObjValue(user, '_id'),
            userSlug: utilsService.getObjValue(user, 'slug'),
            companyId: utilsService.getObjValue(userCompany, '_id'),
            companySlug: utilsService.getObjValue(userCompany, 'slug')
        };
        const response = await fetchService.authenticatedPost(
            `/profiles/${profile._id}/contact-info`, body);
        if (response && response.data) {
            res = response.data;
            this.contactInfo = res;
        }

        return res;
    }

    @action.bound
    appendDataFromSearch(profiles) {
        const normalizedData = utilsService.normalizeApiData(profiles);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
    }

    findProfilesByIds(ids) {
        const profiles = [];
        ids.forEach(id => {
            const profile = Object.keys(this.profiles).find(key => this.profiles[key]._id === id);
            if (profile) {
                profiles.push(profile);
            }
        });
        return profiles;
    }

    findProfilesByIds2(ids) {
        const profiles = [];
        ids.forEach(id => {
            const profile = Object.values(this.profiles).find(value => value._id === id);
            if (profile) {
                profiles.push(profile);
            }
        });
        return profiles;
    }

    findProfilesByEmails(emails) {
        const profiles = [];
        emails.forEach(email => {
            const profile = Object.values(this.profiles).find(item => {
                return item.emails && item.emails.some(element => element.email === email);
            });
            if (profile) {
                profiles.push(profile);
            }
        });
        return profiles;
    }

    findProfileById(id) {
        const profile = Object.values(this.profiles).find(value => value._id === id);
        return profile;
    }

    @action.bound
    async updateProfile(profileId, data) {
        data.newTalent = false;
        let isError = false;
        const resp = await fetchService.authenticatedPatch(`/profiles/${profileId}`, data);
        if (resp.data?.isError) {
            isError = resp.data.errorMessage;
        }
        else {
            this.profiles[resp.data.slug] = resp.data;
            this.profileToShow = resp.data;
        }
        return isError;
    }

    @action.bound
    async removeCompanyDetails(profileId) {
        const data = {
            company: '',
            companyId: null,
            companySlug: null,
            companyLC: null,
            companyEmsId: null
        }
        return this.updateProfile(profileId, data);
    }



    async createProfile(data, saveOverviewProfileShow) {
        data.forEach(element => {
            element.newTalent = false;
        });
        let isError = false;
        const resp = await fetchService.authenticatedPost(`/profiles`, data);
        if (resp && resp.data && resp.data.isError) {
            isError = resp.data.errorMessage;
        }
        else {
            if(resp.data) {
                this.profiles[resp.data.slug] = resp.data;
                this.profileToShow = resp.data;
            }
        }
        return {isError: isError, profileSlug: resp.data?.slug};
    }

    /**
     * @deprecated
     */
    @action.bound
    async updateSummary(profileId, summaryData) {
        let isError = false;
        const resp = await fetchService.authenticatedPatch(`/profiles/${profileId}/update-summary`, summaryData);

        if (resp.data?.isError) {
            isError = resp.data.errorMessage;
        }
        else {
            this.profiles[resp.data.slug] = resp.data;
            this.profileToShow = resp.data;
        }
        return isError;
    }

    @action.bound
    async updateSummaries(profileId, summaries) {
        this.isLoadingSummaries = true;

        const summariesPayload = {
            summaries: summaries.map(summary => ({
                ...(summary._id ? { _id: summary._id } : {}),
                summary: summary.summary,
                createDate: summary.createDate,
                updateDate: summary.updateDate,
                creatorUser: summary.creatorUserId,
                ...(summary.isPrimary ? { isPrimary: summary.isPrimary } : {}),
            }))
        }

        const isError = await profileStore.updateProfile(profileId, summariesPayload);

        if (isError) {
            this.isLoadingSummaries = false;
            notificationsStore.showToast('Summaries updated failed due to technical issues', 'failure');
            return;
        }

        const profileSlug = profileStore.findProfileSlugById(profileId);
        await profileStore.createCoveredCV(profileSlug, undefined);

        this.isLoadingSummaries = false;
        notificationsStore.showToast('Summaries updated successfully', 'success');
    }

    @action.bound
    async updateCVs(profileId, CVsData) {
        let isError = false;
        const resp = await fetchService.authenticatedPatch(`/profiles/${profileId}/update-cvs`, CVsData);

        console.log({resp})

        if (resp.data?.isError) {
            isError = resp.data.errorMessage;
        }
        else {
            this.profiles[resp.data.slug] = resp.data;
            this.profileToShow = resp.data;
        }
        return isError;
    }


    @action.bound
    async updateWishListProfile(slug, wishList) {
        const response = await fetchService.authenticatedPost(`/profiles/${slug}/wish-list`, wishList);
        this.profiles[response.data.slug] = response.data;
        this.profileToShow = response.data
    }

    @action.bound
    async changeCvOwnership(body) {
        const response = await fetchService.authenticatedPost(`/profiles-cvowner`, body);
        if (Array.isArray(response.data)) {
            const normalizedData = utilsService.normalizeApiData(response.data);
            this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
        } else {
            this.profiles[response.data.slug] = response.data;
        }
    }

    @action.bound
    mergeData(newData) {
        const normalizedData = utilsService.normalizeApiData(newData);
        this.profiles = utilsService.mergeApiData(normalizedData, this.profiles);
    }

    @action.bound
    updateLocalProfile(slug, data) {
        this.profiles[slug] = data;
    }

    async sendSuggestions(suggestBody, profilesSlugArr, jobsSlugArr) {
        const response = await fetchService.authenticatedPost(`/suggest`, {suggestBody: suggestBody, profilesSlug: profilesSlugArr, jobsSlug: jobsSlugArr});
        if(response && response.find && response.find(res => res.isError)) {
            return response;
        }
        const res = response.data ? response.data : undefined;
        return res;
    }

    @action.bound
    async getProfileEmails(emails) {
        const existsProfiles = this.findProfilesByEmails(emails)
        const emailsToSend = emails.filter(email => !existsProfiles.some(item => item.emails.some(element => element.email === email)));

        const body = {
            emails: emailsToSend
        }

        let data = [];
        if(emailsToSend.length > 0) {
            const response = await fetchService.authenticatedPost(`/profiles-emails`, body);
            data = response.data;
            const normalizedData = utilsService.normalizeApiData(data);
            this.profiles = utilsService.mergeApiData(normalizedData, this.profiles)
        }
        return [...existsProfiles, ...data]
    }

    async getJobsByProfile(profileId) {
        const response = await fetchService.authenticatedPost(`/profile-jobs`, {profileId: profileId});
        const data = response.data;
        return data;
    }

    initProfilesForJobsMatches() {
        this.profilesJobsMatches = {};
        this.detailedProfilesForJobsMatches = {};
    }

    @action.bound
    async getProfileBySlug(slug) {
        if (this.profiles[slug]) {
            this.profileToShow = this.profiles[slug];
        } else {
            const response = await fetchService.authenticatedGet(`/profiles-slug/${slug}`);
            if(response && response.data) {
                this.profiles[response.data.slug] = response.data;
                this.profileToShow = response.data;
            }
        }

        return this.profileToShow;
    }

    async getMergeProfilesResult(data) {
        const response = await fetchService.authenticatedPost(`/profiles/merge/test`, data);

        if(response && response.data && !response.data.isError) return response.data;

        const errorMessage = response?.data?.errorMessage || '';
        notificationsStore.showToast('Failed to get merge profiles result. ' + errorMessage , 'failure');
    }

    async doMergeProfiles(data) {
        const response = await fetchService.authenticatedPost(`/profiles/merge`, data);
        
        if(response && response.data && !response.data.isError) {
            notificationsStore.showToast('Profiles are merged successfully.', 'success');
            return response.data;
        };

        const errorMessage = response?.data?.errorMessage || '';
        notificationsStore.showToast('Failed to merge profiles. ' + errorMessage , 'failure');
    }

    async getListDuplicateProfile(type = 'list') {
        const response = await fetchService.authenticatedGet(`/duplicate-profile?type=${type}`);

        if(response && response.data && !response.data.isError) return response.data;
        
        // const errorMessage = response?.data?.errorMessage || '';
        // notificationsStore.showToast('Failed to get list duplicate profile. ' + errorMessage , 'failure');
    }

    async createDuplicateProfile(data) {
        const response = await fetchService.authenticatedPost(`/duplicate-profile`, data);

        if(response && response.data && !response.data.isError) return response.data;
        
        // const errorMessage = response?.data?.errorMessage || '';
        // notificationsStore.showToast('Failed to create duplicate profile. ' + errorMessage , 'failure');
    }

    async updateDuplicateProfile(id, data) {
        const response = await fetchService.authenticatedPatch(`/duplicate-profile/${id}`, data);

        if(response && !response.data?.isError) return response.data;
        
        // const errorMessage = response?.data?.errorMessage || '';
        // notificationsStore.showToast('Failed to update duplicate profile. ' + errorMessage , 'failure');
    }

    @action.bound
    async checkCVExist(slug) {
        if (slug) {
            const response = await fetchService.authenticatedGet(`/profiles/${slug}/cv-exist`);
            if(response && response.data) {
                return response.data
            }
            return []
        }
        return []
    }

    @action.bound
    async getAutoGeneratedSummary(body) {
        const response = await fetchService.authenticatedPost(`/generate-summary`, body);
        if(response && response.data && !response.data.isError) {
            return response.data;
        };

        const errorMessage = response?.data?.errorMessage || '';
        notificationsStore.showToast('Failed to generate summary ' + errorMessage , 'failure');
        return ''
    }
}

const profileStore = new ProfileStore();

export default profileStore;

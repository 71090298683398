import React, {Component} from 'react';
import utilsService from '../../../../services/utils.service';
import {TrashIcon,EditIcon, TackHoverIcon, TackPressedIcon, VeeeIcon, CloseIcon, TrashIcon1, EditIcon1, PinIcon} from '../../svg';
import DefaultImageComponent from '../../DefaultImage.component';
import userStore from '../../../../stores/user.store';
import QuillBS from '../../quillBS/QuillBS.component'
import ModernSelectComponent from '../../forms/ModernSelect.component';
import './noteItem.scss';
import notesStore from '../../../../stores/notes.store';

class NoteItemComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            isInEditMode: props.isNewNote,
            userName: '',
            editorHtml: '',
            createNewNoteDate: '',
            selectedTag: props.tagId
        }
        this._tags = [
            {elId: 1, value: 'Primary'}, 
            {elId: 2, value: 'General'}, 
            {elId: 3, value: 'Conversation'},
            {elId: 4, value: 'Phone Interview'},
            {elId: 5, value: 'Reaching out'},
            {elId: 6, value: 'Meeting'}
        ];

        if(!props.hasPhoneInterview) {
            const index = this._tags.findIndex(item => item.elId === 4);
            this._tags.splice(index, 1);
        }
    }

    onEditorStateChange = (editorHtml, delta) => {
        const { isNewNote, isSummary, onEditorChange } = this.props;
        const { isInEditMode } = this.state;
        this.setState({
            editorHtml: editorHtml
        });
        if(isNewNote && !isSummary) {
            notesStore.profilesNotesEditorHtml.notes = editorHtml;
        }

        if(isInEditMode && onEditorChange) {
            onEditorChange(editorHtml)
        }
    };

    componentDidMount() {

        const { text } = this.props;
        
        const userName = userStore.user.displayName;
        const date = new Date();
        const newNoteCreationDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        // this.setState({createNewNoteDate: newNoteCreationDate, loader: false, userName: userName, editorHtml: utilsService.clearHtmlFromDangerousKthulhu(text)});
        this.setState({createNewNoteDate: newNoteCreationDate, loader: false, userName: userName, editorHtml: text ? text : ''});
    }
    componentDidUpdate(prevProps) {
        if(prevProps.tagId !== this.props.tagId) {
            this.setState({selectedTag: this.props.tagId});
        }
    }

    onClickClose = evt => {
        // const { isNewNote } = this.props;
        // if(isNewNote) {
        //     notesStore.initProfilesNotesEditorHtml();
        // }
        // this.setState({isInEditMode: false, editorHtml: ''});
        this.setState({isInEditMode: false});
        this.props.onClickClose();
    }
    onClickSave = () => {
        const { isNewNote, onClickSave, noteId } = this.props;
        const { editorHtml, selectedTag } = this.state;
        if(isNewNote) {
            notesStore.initProfilesNotesEditorHtml();
        }
        onClickSave(editorHtml, noteId, this.props.isNewNote, undefined, selectedTag)
        this.setState({editorHtml: "" });
    }
    handleSelectTag = item => {
        this.setState({selectedTag: item.elId});
    }
    getTagText = () => {
        const {selectedTag} = this.state;
        const tag = this._tags.find(tag => tag.elId === selectedTag);
        return tag ? tag.value : 'General';
    }
    getTagClass = () => {
        const {selectedTag} = this.state;
        const tag = this._tags.find(tag => tag.elId === selectedTag);
        let tagClass = 'tag-general-conversation';
        if(tag && tag.elId === 1) {
            tagClass = 'tag-primary';
        }

        return tagClass;
    }

    render() {
        const { isSummary, onClickSave, creatorName, creatorDate, noteId, onClickDelete, isTackOn, creatorImage, isShowTags, isNewVersion } = this.props;
        const { isInEditMode, editorHtml, selectedTag } = this.state;

        let dateAndTime = creatorDate ? utilsService.getFormattedDate(creatorDate,4) : utilsService.getFormattedDate(Date.now(),4);
        if(!isInEditMode && creatorDate) {
            dateAndTime = utilsService.getFormattedDate(creatorDate,4);
        }

        if (isNewVersion) {
            dateAndTime = utilsService.getFormattedDate(creatorDate ?? Date.now(), 6);
        }

        return (
            <div className='note-item'>

                {!isInEditMode && (
                    <div className="icons-container-1">
                        {isShowTags && (
                            <div className={"note-tag-container " + this.getTagClass()}>
                                <span>{this.getTagText()}</span>
                            </div>
                        )}

                        {!isSummary && isTackOn && (
                            <div onClick={() => onClickSave(editorHtml, noteId, this.props.isNewNote, true, selectedTag)}>
                                <TackPressedIcon/>
                            </div>
                        )}
                        {!isSummary && !isTackOn && (
                            <div onClick={() => onClickSave(editorHtml, noteId, this.props.isNewNote, true, selectedTag)}>
                                {isNewVersion ? <PinIcon /> : <TackHoverIcon/>}
                            </div>
                        )}
                        {isSummary && (
                            <div/>
                        )}

                        <div onClick={() => this.setState({isInEditMode: true})}>
                            {isNewVersion ? <EditIcon1 /> : <EditIcon/>}
                        </div>
                        <div onClick={() => onClickDelete(noteId)}>
                            {isNewVersion ? <TrashIcon1 /> : <TrashIcon/>}
                        </div>
                    </div>
                )}
                {isInEditMode && (
                    <div className="icons-container-2">
                        {isShowTags && (
                            <div className="tags-selection-container">
                                <ModernSelectComponent
                                    list={this._tags}
                                    disabled={false}
                                    indexMark='elId'
                                    valueMark='value'
                                    selected={selectedTag}
                                    title={'Tag:'}
                                    selectEvent={this.handleSelectTag}
                                />
                            </div>
                        )}

                        <div 
                            className="close-icon-container"
                            onClick={this.onClickClose}>
                            <CloseIcon/>
                        </div>
                        <div className={'vee-icon-container ' + (editorHtml && (editorHtml.length === 0 || editorHtml === '<p><br></p>') ? 'prevent-closing' : '')} 
                            onClick={() => this.onClickSave()}>
                            <VeeeIcon/>
                        </div>
                    </div>
                )}
                <div className="text-container">
                    <QuillBS 
                        html={editorHtml}
                        isInEditMode={isInEditMode}
                        onChange={this.onEditorStateChange}
                    />
                </div>
                <div className="footer-container">
                    {creatorName && (
                        <React.Fragment>
                            <DefaultImageComponent
                                url={creatorImage}
                                alt={creatorName}
                                profileName={creatorName}
                            />
                            <span className="footer-text">{utilsService.capitalizeString(creatorName)}, {dateAndTime}</span>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default NoteItemComponent;

NoteItemComponent.defaultProps = {
    hasPhoneInterview: true,
};
